import env from '../env.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
      shortDesc: "Medication Management System."
    },
    windowWidth: null,
    countries: [],
    localGovernments: [],
    dashboardData:{
      "patients":{
        "total": 0,
        "on_queue": 0,
        "discharged": 0,
        "scheduled": 0,
        "checked_out": 0
      },
      "medical_histories": 0,
      "hospital": null,
    },
}

export default state
