export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '/main',
      redirect: '/',
      component: () => import('@/layouts/Main.vue'),
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@views/Dashboard.vue'),
          meta: {
            title: 'Dashboard',
            authRequired: true,
          }
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@views/account/UserEdit.vue'),
          meta: {
            title: 'Account',
            authRequired: true,
          },
        },
        {
          path: '/patients',
          name: 'patients',
          component: () => import('@views/patients/PatientList.vue'),
          meta: {
            title: "All Patients Assigned to you",
            authRequired: true,
          },
        },
        {
          path: '/patients/:itemId(\\d+)',
          name: 'patient-show',
          component: () => import('@views/patients/PatientShow.vue'),
          meta: {
            title: "View Patient",
            authRequired: true,
          },
        },
        {
          path: '/medical-histories',
          name: 'medical-histories',
          component: () => import('@views/medical-histories/Histories.vue'),
          meta: {
            title: "Medical Histories",
            authRequired: true,
          },
        },
        {
          path: '/medical-histories/:historyId(\\d+)',
          name: 'history-show',
          component: () => import('@views/medical-histories/HistoryShow.vue'),
          meta: {
            title: "View Medical Record",
            authRequired: true,
          },
        },
        {
          path: '/ailments',
          name: 'ailments',
          component: () => import('@views/ailments/Ailments.vue'),
          meta: {
            title: "Ailments",
            authRequired: true,
          }
        },
        {
          path: '/notifications',
          name: 'notifications',
          component: () => import('@views/notifications/NotificationList.vue'),
          meta: {
            title: "Notifications",
            authRequired: true,
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
              title: "404 - Page not Found"
            }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: {
              title: "500 - Server error"
            }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]